{
  "a11y": {
    "loading_page": "Seite wird geladen, bitte warten",
    "loading_titled_page": "Seite {0} wird geladen, bitte warten",
    "locale_changed": "Sprache wurde zu {0} geändert",
    "locale_changing": "Sprache wird geändert, bitte warten",
    "route_loaded": "Seite {0} geladen"
  },
  "account": {
    "avatar_description": "{0}'s Avatar",
    "blocked_by": "Du wurdest von diesem Account geblockt",
    "blocked_domains": "Gesperrte Domänen",
    "blocked_users": "Gesperrte Accounts",
    "blocking": "Blockiert",
    "bot": "BOT",
    "favourites": "Favoriten",
    "follow": "Folgen",
    "follow_back": "Zurück folgen",
    "follow_requested": "Angefragt",
    "followers": "Follower",
    "followers_count": "{0} Follower",
    "following": "Du folgst",
    "following_count": "Folgt {0}",
    "follows_you": "Folgt dir",
    "go_to_profile": "Gehe zum Profil",
    "joined": "Beigetreten",
    "moved_title": "hat angegeben, dass dies der neue Account ist:",
    "muted_users": "Stummgeschaltete Accounts",
    "muting": "Stummgeschaltet",
    "mutuals": "Freunde",
    "notify_on_post": "Benachrichtige mich, wenn {username} etwas postet",
    "pinned": "Angepinnt",
    "posts": "Beiträge",
    "posts_count": "{0} Beiträge",
    "profile_description": "{0}'s Profil",
    "profile_unavailable": "Profil nicht verfügbar",
    "unblock": "Entblocken",
    "unfollow": "Entfolgen",
    "unmute": "Stummschaltung aufheben",
    "view_other_followers": "Follower aus anderen Instanzen werden möglicherweise nicht angezeigt.",
    "view_other_following": "Das Folgen von anderen Instanzen wird möglicherweise nicht angezeigt."
  },
  "action": {
    "apply": "Anwenden",
    "bookmark": "Lesezeichen",
    "bookmarked": "Gemerkt",
    "boost": "Teilen",
    "boost_count": "{0}",
    "boosted": "Geteilt",
    "clear_upload_failed": "Fehler beim Hochladen von Dateien entfernen",
    "close": "Schliessen",
    "compose": "Verfassen",
    "confirm": "Bestätigen",
    "edit": "Bearbeiten",
    "enter_app": "App öffnen",
    "favourite": "Favorisieren",
    "favourite_count": "{0}",
    "favourited": "Favorisiert",
    "more": "Mehr",
    "next": "Nächster",
    "prev": "Vorheriger",
    "publish": "Veröffentlichen",
    "reply": "Antworten",
    "reply_count": "{0}",
    "reset": "Zurücksetzen",
    "save": "Speichern",
    "save_changes": "Änderungen speichern",
    "sign_in": "Anmelden",
    "switch_account": "Account wechseln",
    "vote": "Abstimmen"
  },
  "app_desc_short": "Ein flinker Mastodon Web-Client",
  "app_logo": "Elk Logo",
  "app_name": "Elk",
  "attachment": {
    "edit_title": "Beschreibung",
    "remove_label": "Anhang entfernen"
  },
  "command": {
    "activate": "Aktivieren",
    "complete": "Vollständig",
    "compose_desc": "Schreib einen neuen Beitrag",
    "n-people-in-the-past-n-days": "{0} Personen in den vergangenen {1} Tagen",
    "select_lang": "Sprache auswählen",
    "sign_in_desc": "Bestehenden Account hinzufügen",
    "switch_account": "Zu {0} wechseln",
    "switch_account_desc": "Zu einem anderen Account wechseln",
    "toggle_dark_mode": "Farbschema ändern",
    "toggle_zen_mode": "Zen-Modus ändern"
  },
  "common": {
    "end_of_list": "Ende der Liste",
    "error": "FEHLER",
    "in": "in",
    "not_found": "404 Nicht Gefunden",
    "offline_desc": "Anscheinend bist du offline. Bitte überprüfe deine Netzwerkverbindung."
  },
  "compose": {
    "draft_title": "Entwurf {0}",
    "drafts": "Entwürfe ({v})"
  },
  "confirm": {
    "common": {
      "cancel": "Abbrechen",
      "confirm": "OK",
      "title": "Bist du sicher, {0}?"
    },
    "delete_posts": {
      "cancel": "Abbrechen",
      "confirm": "Löschen",
      "title": "Möchtest du diesen Beitrag wirklich löschen?"
    }
  },
  "conversation": {
    "with": "mit"
  },
  "error": {
    "account_not_found": "Account {0} nicht gefunden",
    "explore-list-empty": "Momentan ist nichts im Trend. Schau später nochmal vorbei!",
    "file_size_cannot_exceed_n_mb": "Die Dateigröße darf {0} MB nicht überschreiten",
    "sign_in_error": "Kann nicht zu Server verbinden",
    "status_not_found": "Beitrag nicht gefunden",
    "unsupported_file_format": "Nicht unterstütztes Dateiformat"
  },
  "help": {
    "desc_highlight": "Erwarte hier und da einige Bugs und fehlende Funktionen.",
    "desc_para1": "Danke für dein Interesse in Elk, unser noch in der Bearbeitung befindliche, generische Mastodon Client!",
    "desc_para2": "Wir arbeiten hart an der Entwicklung und verbessern ihn mit der Zeit. Und wir laden dich schon sehr bald ein uns zu helfen, sobald wir ihn Quelloffen machen!",
    "desc_para3": "Doch in der Zwischenzeit kannst du der Entwicklung aushelfen, indem du unsere Teammitglieder durch die unten stehenden Links unterstützt.",
    "desc_para4": "Elk ist Open Source. \nWenn du beim Testen helfen, Feedback geben oder einen Beitrag leisten möchtest,",
    "desc_para5": "Kontaktiere uns auf GitHub",
    "desc_para6": "und mache mit.",
    "title": "Elk ist in der Alpha!"
  },
  "language": {
    "search": "Suche"
  },
  "menu": {
    "block_account": "Sperre {0}",
    "block_domain": "Sperre Domain {0}",
    "copy_link_to_post": "Link zu diesem Beitrag kopieren",
    "delete": "Löschen",
    "delete_and_redraft": "Löschen und neu erstellen",
    "direct_message_account": "Direktnachricht an {0}",
    "edit": "Bearbeiten",
    "hide_reblogs": "Boosts von {0} ausblenden",
    "mention_account": "Erwähne {0}",
    "mute_account": "{0} stummschalten",
    "mute_conversation": "Diesem Beitrag stummschalten",
    "open_in_original_site": "Auf Originalseite öffnen",
    "pin_on_profile": "An Profil anpinnen",
    "share_post": "Teile diesen Beitrag",
    "show_favourited_and_boosted_by": "Zeige mir, wer favorisiert und geboostet hat",
    "show_reblogs": "Boosts von {0} anzeigen",
    "show_untranslated": "Übersetzung schliessen",
    "toggle_theme": {
      "dark": "Dunkles Farbschema aktivieren",
      "light": "Helles Farbschema aktivieren"
    },
    "translate_post": "Beitrag übersetzen",
    "unblock_account": "Entsperre {0}",
    "unblock_domain": "Entsperren Domain {0}",
    "unmute_account": "Stummschaltung von {0} aufheben",
    "unmute_conversation": "Stummschaltung aufheben",
    "unpin_on_profile": "Von Profil lösen"
  },
  "nav": {
    "back": "Zurück",
    "blocked_domains": "Gesperrte Domänen",
    "blocked_users": "Blockierte Benutzer",
    "bookmarks": "Lesezeichen",
    "built_at": "Letzter Build: {0}",
    "compose": "Verfassen",
    "conversations": "Direktnachrichten",
    "explore": "Entdecken",
    "favourites": "Favoriten",
    "federated": "Föderiert",
    "home": "Startseite",
    "local": "Lokal",
    "muted_users": "Stummgeschaltete Benutzer",
    "notifications": "Mitteilungen",
    "profile": "Profil",
    "search": "Suche",
    "select_feature_flags": "Feature-Flags aktivieren",
    "select_font_size": "Schriftgröße",
    "select_language": "Sprache auswählen",
    "settings": "Einstellungen",
    "show_intro": "Intro anzeigen",
    "toggle_theme": "Farbschema wechseln",
    "zen_mode": "Zen-Modus"
  },
  "notification": {
    "favourited_post": "hat deinen Beitrag favorisiert",
    "followed_you": "folgt dir",
    "followed_you_count": "{n} Account folgt dir|{n} Accounts folgen dir",
    "missing_type": "MISSING notification.type:",
    "reblogged_post": "hat deinen Beitrag geteilt",
    "request_to_follow": "möchte dir folgen",
    "signed_up": "hat sich registriert",
    "update_status": "hat diesen Beitrag aktualisiert"
  },
  "placeholder": {
    "content_warning": "Schreib hier deine Warnung",
    "default_1": "Was geht dir gerade durch den Kopf?",
    "reply_to_account": "Antwort an {0}",
    "replying": "Antworten",
    "the_thread": "der Thread"
  },
  "pwa": {
    "dismiss": "Ignorieren",
    "title": "Neues Elk-Update verfügbar!",
    "update": "Aktualisieren",
    "update_available_short": "Elk aktualisieren",
    "webmanifest": {
      "canary": {
        "description": "Ein flinker Mastodon-Webclient (Canary)",
        "name": "Elk (canary)",
        "short_name": "Elk (canary)"
      },
      "dev": {
        "description": "Ein flinker Mastodon-Webclient (dev)",
        "name": "Elk (dev)",
        "short_name": "Elk (dev)"
      },
      "preview": {
        "description": "Ein flinker Mastodon-Webclient (Vorschau)",
        "name": "Elk (Vorschau)",
        "short_name": "Elk (Vorschau)"
      },
      "release": {
        "description": "Ein flinker Mastodon-Webclient",
        "name": "Elk",
        "short_name": "Elk"
      }
    }
  },
  "search": {
    "search_desc": "Suche nach Accounts & Hashtags",
    "search_empty": "Nichts für diese Suchbegriffe gefunden"
  },
  "settings": {
    "about": {
      "label": "Info",
      "meet_the_team": "Triff das Team",
      "sponsor_action": "Sponser uns",
      "sponsor_action_desc": "Um das Team bei der Entwicklung von Elk zu unterstützen",
      "sponsors": "Sponsoren",
      "sponsors_body_1": "Elk wird ermöglicht durch das großzügige Sponsoring und die Hilfe von:",
      "sponsors_body_2": "Und alle Unternehmen und Einzelpersonen, die das Elk Team und die Mitglieder sponsern.",
      "sponsors_body_3": "Wenn dir die App gefällt, erwäge uns zu sponsern:"
    },
    "account_settings": {
      "description": "Bearbeite Kontoeinstellungen in der Mastodon-Benutzeroberfläche",
      "label": "Account Einstellungen"
    },
    "feature_flags": {
      "github_cards": "GitHub Cards",
      "title": "Experimentelle Funktionen",
      "user_picker": "Benutzerauswahl",
      "virtual_scroll": "Virtuelles Scrollen"
    },
    "interface": {
      "color_mode": "Farbschema",
      "dark_mode": "Dunkles Farbschema",
      "default": " (Standard)",
      "font_size": "Schriftgröße",
      "label": "Oberfläche",
      "light_mode": "Helles Farbschema",
      "system_mode": "System"
    },
    "language": {
      "display_language": "Anzeigesprache",
      "label": "Sprache",
      "translations": {
        "add": "Hinzufügen",
        "choose_language": "Sprache wählen",
        "heading": "Übersetzungen",
        "hide_specific": "Bestimmte Übersetzungen ausblenden",
        "remove": "Entfernen"
      }
    },
    "notifications": {
      "label": "Benachrichtigungen",
      "notifications": {
        "label": "Benachrichtigungseinstellungen"
      },
      "push_notifications": {
        "alerts": {
          "favourite": "Favoriten",
          "follow": "Neue Follower",
          "mention": "Erwähnungen",
          "poll": "Umfragen",
          "reblog": "Reblogge deinen Beitrag",
          "title": "Welche Benachrichtigungen erhalten?"
        },
        "description": "Erhalte Benachrichtigungen, auch wenn du Elk nicht verwendest.",
        "instructions": "Vergesse nicht, Änderungen mit der Schaltfläche @:settings.notifications.push_notifications.save_settings zu speichern!",
        "label": "Einstellungen für Push-Benachrichtigungen",
        "policy": {
          "all": "Von irgendjemandem",
          "followed": "Von Accounts, denen ich folge",
          "follower": "Von Menschen, die mir folgen",
          "none": "Von niemandem",
          "title": "Von wem kann ich Benachrichtigungen erhalten?"
        },
        "save_settings": "Einstellungen speichern",
        "subscription_error": {
          "clear_error": "Fehler aufräumen",
          "permission_denied": "Berechtigung verweigert: Aktiviere Benachrichtigungen im  Browser.",
          "request_error": "Beim Anfordern des Abonnements ist ein Fehler aufgetreten. Versuche es erneut. Wenn der Fehler weiterhin besteht, melde das Problem bitte dem Elk-Repository.",
          "title": "Push-Benachrichtigungen konnten nicht abonniert werden",
          "too_many_registrations": "Aufgrund von Browserbeschränkungen kann Elk den Push-Benachrichtigungsdienst nicht für mehrere Konten auf verschiedenen Servern verwenden. \nDu solltest Push-Benachrichtigungen für andere Konten abbestellen und es erneut versuchen."
        },
        "title": "Einstellungen für Push-Benachrichtigungen",
        "undo_settings": "Änderungen rückgängig machen",
        "unsubscribe": "Push-Benachrichtigungen deaktivieren",
        "unsupported": "Ihr Browser unterstützt keine Push-Benachrichtigungen.",
        "warning": {
          "enable_close": "Schließen",
          "enable_description": "Um Benachrichtigungen zu erhalten, wenn Elk nicht geöffnet ist, aktiviere Push-Benachrichtigungen. \nDu kannst genau steuern, welche Arten von Interaktionen Push-Benachrichtigungen generieren, indem du die Schaltfläche \"@:settings.notifications.show_btn{'\"'} oben aktivierest, sobald sie aktiviert ist.",
          "enable_description_desktop": "Um Benachrichtigungen zu erhalten, wenn Elk nicht geöffnet ist, aktiviere Push-Benachrichtigungen. \nDu kannst unter „Einstellungen > Benachrichtigungen > Einstellungen für Push-Benachrichtigungen“ genau steuern, welche Arten von Interaktionen Push-Benachrichtigungen generieren, sobald diese aktiviert sind.",
          "enable_description_mobile": "Du erreichst die Einstellungen auch über das Navigationsmenü „Einstellungen > Benachrichtigungen > Push-Benachrichtigungseinstellungen“.",
          "enable_description_settings": "Um Benachrichtigungen zu erhalten, wenn Elk nicht geöffnet ist, aktiviere Push-Benachrichtigungen. \nDu kannst genau steuern, welche Arten von Interaktionen Push-Benachrichtigungen auf demselben Bildschirm generieren, sobald du sie aktivierst.",
          "enable_desktop": "Aktiviere Push-Benachrichtigungen",
          "enable_title": "Verpasse nie wieder Benachrichtigungen",
          "re_auth": "Offenbar unterstützt dein Server keine Push-Benachrichtigungen. \nVersuche dich abzumelden und erneut anzumelden. Wenn diese Meldung weiterhin angezeigt wird, wende dich an dein Serveradministrator."
        }
      },
      "show_btn": "Gehe zu den Benachrichtigungseinstellungen"
    },
    "notifications_settings": "Benachrichtigungen",
    "preferences": {
      "enable_autoplay": "Autoplay aktivieren",
      "github_cards": "GitHub Cards",
      "hide_boost_count": "Boost-Zähler ausblenden",
      "hide_favorite_count": "Favoritenzahl ausblenden",
      "hide_follower_count": "Anzahl der Follower ausblenden",
      "hide_translation": "Übersetzungen komplett ausblenden",
      "label": "Einstellungen",
      "title": "Experimentelle Funktionen",
      "user_picker": "Benutzerauswahl",
      "virtual_scroll": "Virtuelles Scrollen"
    },
    "profile": {
      "appearance": {
        "bio": "Bio",
        "description": "Avatar, Benutzername, Profil, etc. bearbeiten",
        "display_name": "Anzeigename",
        "label": "Erscheinungsbild",
        "profile_metadata": "Profil-Metadaten",
        "profile_metadata_desc": "Du kannst bis zu vier Einträge als Tabelle in deinem Profil anzeigen lassen",
        "title": "Profil bearbeiten"
      },
      "featured_tags": {
        "description": "Leute können deine öffentlichen Beiträge mit diesen Hashtags ansehen.",
        "label": "Ausgewählte Hashtags"
      },
      "label": "Profil"
    },
    "select_a_settings": "Einstellung auswählen",
    "users": {
      "export": "Benutzer-Token exportieren",
      "import": "Benutzer-Token importieren",
      "label": "Eingeloggte Benutzer"
    }
  },
  "share-target": {
    "description": "Elk kann so konfiguriert werden, dass du Inhalte aus anderen Anwendungen teilen kannst, installiere einfach Elk auf deinem Gerät oder Computer und melden dich an.",
    "hint": "Um Inhalte mit Elk zu teilen, muss Elk installiert sein und du musst angemeldet sein.",
    "title": "Teile via Elk"
  },
  "state": {
    "attachments_exceed_server_limit": "Die Anzahl der Anhänge hat das Limit pro Beitrag überschritten.",
    "attachments_limit_error": "Limit pro Beitrag überschritten",
    "edited": "(bearbeitet)",
    "editing": "Bearbeiten",
    "loading": "Laden...",
    "publishing": "Veröffentlichung",
    "upload_failed": "Upload fehlgeschlagen",
    "uploading": "Hochladen..."
  },
  "status": {
    "boosted_by": "Boosted von",
    "edited": "Zuletzt bearbeitet: {0}",
    "favourited_by": "Favorisiert von",
    "filter_hidden_phrase": "Versteckt durch",
    "filter_show_anyway": "Trotzdem zeigen",
    "img_alt": {
      "desc": "Beschreibung",
      "dismiss": "Schliessen"
    },
    "poll": {
      "count": "{0} Stimmen|{0} Stimme|{0} Stimmen",
      "ends": "Endet: {0}",
      "finished": "Beendet: {0}"
    },
    "reblogged": "{0} teilte",
    "replying_to": "Antworten auf {0}",
    "show_full_thread": "Vollständigen Thread anzeigen",
    "someone": "Jemand",
    "spoiler_show_less": "Zeige weniger",
    "spoiler_show_more": "Zeige mehr",
    "thread": "Thread",
    "try_original_site": "Versuche die original Seite"
  },
  "status_history": {
    "created": "Erstellt: {0}",
    "edited": "Bearbeitet: {0}"
  },
  "tab": {
    "for_you": "Für dich",
    "hashtags": "Hashtags",
    "media": "Medien",
    "news": "Nachrichten",
    "notifications_all": "Alle",
    "notifications_mention": "Erwähnungen",
    "posts": "Beiträge",
    "posts_with_replies": "Beiträge und Antworten"
  },
  "tag": {
    "follow": "Folgen",
    "follow_label": "Folge dem {0}-Tag",
    "unfollow": "Entfolgen",
    "unfollow_label": "Entfolge dem {0}-Tag"
  },
  "time_ago_options": {
    "day_future": "in 0 Tagen|morgen|in {n} Tagen",
    "day_past": "vor 0 Tagen|gestern|vor {n} Tagen",
    "hour_future": "in 0 Stunden|in 1 Stunde|in {n} Stunden",
    "hour_past": "vor 0 Stunden|vor 1 Stunde|vor {n} Stunden",
    "just_now": "gerade eben",
    "minute_future": "in 0 Minuten|in 1 Minute|in {n} Minuten",
    "minute_past": "vor 0 Minuten|vor 1 Minute|vor {n} Minuten",
    "month_future": "in 0 Monaten|nächsten Monat|in {n} Monaten",
    "month_past": "vor 0 Monaten|letzten Monat|vor {n} Monaten",
    "second_future": "gerade eben|in {n} Sekunde|in {n} Sekunden",
    "second_past": "gerade eben|vor {n} Sekunde|vor {n} Sekunden",
    "short_day_future": "in {n}T",
    "short_day_past": "{n}T",
    "short_hour_future": "in {n}Std",
    "short_hour_past": "{n}Std",
    "short_minute_future": "in {n}Min",
    "short_minute_past": "{n}Min",
    "short_month_future": "in {n}Mo",
    "short_month_past": "{n}Mo",
    "short_second_future": "in {n}Sek",
    "short_second_past": "{n}Sek",
    "short_week_future": "in {n}W",
    "short_week_past": "{n}W",
    "short_year_future": "in {n}J",
    "short_year_past": "{n}J",
    "week_future": "in 0 Wochen|nächste Woche|in {n} Wochen",
    "week_past": "vor 0 Wochen|letzte Woche|vor {n} Wochen",
    "year_future": "in 0 Jahren|nächstes Jahr|in {n} Jahren",
    "year_past": "vor 0 Jahren|letztes Jahren|vor {n} Jahren"
  },
  "timeline": {
    "show_new_items": "Zeige {v} neue Beiträge|Zeige {v} neuen Beitrag|Zeige {v} neue Beiträge",
    "view_older_posts": "Ältere Beiträge aus anderen Instanzen werden möglicherweise nicht angezeigt."
  },
  "title": {
    "federated_timeline": "Föderierte Timeline",
    "local_timeline": "Lokale Timeline"
  },
  "tooltip": {
    "add_content_warning": "Inhaltswarnung hinzufügen",
    "add_emojis": "Emojis hinzufügen",
    "add_media": "Bilder, ein Video oder eine Audiodatei hinzufügen",
    "add_publishable_content": "Füge Inhalte zum Veröffentlichen hinzu",
    "change_content_visibility": "Sichtbarkeit von Inhalten ändern",
    "change_language": "Sprache ändern",
    "emoji": "Emoji",
    "explore_links_intro": "Diese Nachrichten werden gerade von Leuten auf diesem und anderen Servern des dezentralen Netzwerks besprochen.",
    "explore_posts_intro": "Diese Beiträge von diesem Server gewinnen gerade unter den Leuten von diesem und anderen Servern des dezentralen Netzwerks an Reichweite.",
    "explore_tags_intro": "Diese Hashtags gewinnen gerade unter den Leuten von diesem und anderen Servern des dezentralen Netzweks an Reichweite.",
    "toggle_code_block": "Codeblock umschalten"
  },
  "user": {
    "add_existing": "Bestehendes Konto hinzufügen",
    "server_address_label": "Mastodon Server Adresse",
    "sign_in_desc": "Melde dich an, um Profilen oder Hashtags zu folgen, Beiträge zu favorisieren, zu teilen und zu beantworten oder von deinem Konto auf einem anderen Server aus zu interagieren.",
    "sign_in_notice_title": "Anzeigen von {0} öffentlichen Daten",
    "sign_out_account": "{0} abmelden",
    "tip_no_account": "Wenn du noch kein Mastodon-Konto hast, {0}.",
    "tip_register_account": "wähle einen Server aus und registriere eines"
  },
  "visibility": {
    "direct": "Nur erwähnte Accounts",
    "direct_desc": "Nur für erwähnte Accounts sichtbar",
    "private": "Nur Follower",
    "private_desc": "Nur für Follower sichtbar",
    "public": "Öffentlich",
    "public_desc": "Für alle sichtbar",
    "unlisted": "Nicht gelistet",
    "unlisted_desc": "Für alle sichtbar, aber Erkennungsfunktionen deaktiviert"
  }
}
